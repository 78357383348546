const LoaderDots = [
    { bgColor: 'bg-regentBlue', delay: 'animation-delay-100' },
    { bgColor: 'bg-blue-200', delay: 'animation-delay-200' },
    { bgColor: 'bg-londonHue', delay: 'animation-delay-300' },
    { bgColor: 'bg-fuchsia-100', delay: 'animation-delay-400' },
    { bgColor: 'bg-lavenderPink', delay: 'animation-delay-500' },
];

const Loader = ({
    size = '20px',
    marginY = 'my-5',
    alignY = 'items-center',
    bgColor,
    className = '',
}: {
    size?: string;
    marginY?: string;
    alignY?: string;
    bgColor?: string;
    className?: string;
}) => (
    <div
        className={`relative flex h-full w-full items-center justify-center ${marginY} ${className}`}
        style={{ '--size': size } as React.CSSProperties}
    >
        {LoaderDots.map((dot, index) => (
            <div
                key={index}
                className={`inline-block h-[var(--size)] w-[var(--size)] animate-slide rounded-full ${dot.bgColor} ${dot.delay}`}
            />
        ))}
    </div>
);

export default Loader;
