import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IOrderHistoryDetailedViewModel } from 'Types/Litium';

interface PassWordResultData {
    successMessage?: string;
}

interface UpdateMyInformationResultData {
    successMessage?: string;
}

interface UpdateMySubscriptionResultData {
    successMessage?: string;
}

export const myPageApi = createApi({
    reducerPath: 'myPageApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/mypages/',
        prepareHeaders: headers => {
            headers.set(
                'litium-request-context',
                JSON.stringify(window.__litium.requestContext)
            );
            headers.set(
                'RequestVerificationToken',
                window.localStorage.getItem('requestVerificationToken')
            );
            headers.set('Content-Type', 'application/json');
            return headers;
        },

        credentials: 'include',
    }),
    endpoints: builder => ({
        changePassword: builder.mutation<
            PassWordResultData,
            { form: HTMLFormElement }
        >({
            query: ({ form }) => ({
                method: 'POST',
                url: 'changepassword',
                body: JSON.stringify(Object.fromEntries(new FormData(form))),
                formData: true,
            }),
        }),
        myInformation: builder.mutation<
            UpdateMyInformationResultData,
            { form: HTMLFormElement }
        >({
            query: ({ form }) => ({
                method: 'POST',
                url: 'myinformation',
                body: JSON.stringify(Object.fromEntries(new FormData(form))),
                formData: true,
            }),
        }),
        mySubscription: builder.mutation<
            UpdateMySubscriptionResultData,
            { form: HTMLFormElement }
        >({
            query: ({ form }) => ({
                method: 'POST',
                url: 'subscriptions',
                body: JSON.stringify(Object.fromEntries(new FormData(form))),
                formData: true,
            }),
        }),
        getOrder: builder.query<IOrderHistoryDetailedViewModel, string>({
            query: orderId => ({
                url: `order/${orderId}`,
                method: 'GET',
                headers: {
                    RequestVerificationToken: window.localStorage.getItem(
                        'requestVerificationToken'
                    ),
                },
            }),
        }),
    }),
});

export const {
    useChangePasswordMutation,
    useMyInformationMutation,
    useMySubscriptionMutation,
    useLazyGetOrderQuery,
} = myPageApi;
