export const mergeStateWithPreloaded = <T extends Record<string, unknown>>(
    reducerKey: string,
    initialState: T
) => {
    const preloadedState =
        (typeof window !== 'undefined' &&
            (window.__litium?.preloadState?.[reducerKey] as T)) ||
        {};

    return {
        ...initialState,
        ...preloadedState,
    };
};
