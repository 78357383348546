import { createSlice } from '@reduxjs/toolkit';
import { InputModelArray } from 'Components/Forms/RenderFormField';
import { mergeStateWithPreloaded } from 'Scripts/Utils/mergeStateWithPreloaded';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IMyInformationViewModel } from 'Scripts/Types/Litium';

export interface IFieldValue {
    value: string;
    name: string;
    type: string;
}

interface MyProfilePageReducer {
    inputFields: InputModelArray;
}

export interface UpdateFieldValue {
    data: IFieldValue;
    key: 'myInformation' | 'mySubscription';
}

interface IMyProfilePage extends IMyInformationViewModel {
    myInformation: {
        inputFields: IMyInformationViewModel['inputFields'];
    };
    mySubscription: {
        inputFields: IMyInformationViewModel['inputFields'];
    };
}

const initialState = {
    myInformation: {
        inputFields: [],
    },
    mySubscription: {
        inputFields: [],
    },
};

// slice
const slice = createSlice({
    name: 'myProfilePage',
    initialState: mergeStateWithPreloaded(
        'myProfilePage',
        initialState
    ) as IMyProfilePage,
    reducers: {
        updateFieldValue: (state, action: PayloadAction<UpdateFieldValue>) => {
            const { data, key } = action.payload;
            const stateReducer = state[key] as MyProfilePageReducer;
            const selectedField: IFieldValue | undefined =
                stateReducer.inputFields.find(
                    field => field.name === data.name
                );
            const type = data.type as
                | 'text'
                | 'number'
                | 'email'
                | 'password'
                | 'checkbox'
                | 'select';

            if (selectedField) {
                switch (type) {
                    case 'text':
                    case 'email':
                    case 'number':
                    case 'password':
                    case 'select':
                        selectedField.value = data.value;
                        break;
                    case 'checkbox':
                        if ('checked' in selectedField) {
                            selectedField.value = data.value;
                            selectedField.checked = data.value === 'true';
                        }

                        break;
                    default: {
                        const exhaustiveCheck: never = type;
                        return exhaustiveCheck;
                    }
                }
            }
        },
        populateMyInformationInputFields: (
            state,
            action: PayloadAction<IMyInformationViewModel['inputFields']>
        ) => {
            state.myInformation.inputFields = action.payload;
        },
        populateMySubscriptionInputFields: (
            state,
            action: PayloadAction<IMyInformationViewModel['inputFields']>
        ) => {
            state.mySubscription.inputFields = action.payload;
        },
    },
});

export const {
    updateFieldValue,
    populateMyInformationInputFields,
    populateMySubscriptionInputFields,
} = slice.actions;
export default slice.reducer;
