import { createSlice } from '@reduxjs/toolkit';
import { ICategoryPageViewModel } from 'Types/Litium';
import { mergeStateWithPreloaded } from 'Scripts/Utils/mergeStateWithPreloaded';
import { productFilterApi } from 'Services/productFilterApi';

export const initialState = {};
// slice
const slice = createSlice({
    name: 'productListingPage',
    initialState: mergeStateWithPreloaded(
        'productListingPage',
        initialState
    ) as ICategoryPageViewModel,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(
            productFilterApi.endpoints.getFiltration.matchFulfilled,
            (state, { payload }) => {
                Object.assign(state, payload);
            }
        );
    },
});

export default slice.reducer;
