import { createSlice } from '@reduxjs/toolkit';
import { mergeStateWithPreloaded } from 'Scripts/Utils/mergeStateWithPreloaded';
import type { PayloadAction } from '@reduxjs/toolkit';
import { loginApi } from 'Services/loginApi';
import {
    ISelectModel,
    IChangePasswordViewModel,
    ICreateAccountViewModel,
    IForgotPasswordViewModel,
    ILoginViewModel,
} from 'Scripts/Types/Litium';

export interface IFieldValue {
    value: string;
    name: string;
    type: string;
}

export interface UpdateFieldValue {
    data: IFieldValue;
    key: 'login' | 'forgotPassword' | 'changePassword' | 'createAccount';
}

interface LoginPage {
    login: ILoginViewModel;
    forgotPassword: IForgotPasswordViewModel;
    changePassword: IChangePasswordViewModel;
    createAccount: ICreateAccountViewModel;
}

const initialState = {
    login: {
        canLogin: true,
        inputFields: [],
    },
    forgotPassword: {
        canRequestNewPassword: true,
        inputFields: [],
    },
    changePassword: {
        inputFields: [],
    },
    createAccount: {
        inputFields: [],
    },
};

const slice = createSlice({
    name: 'loginPage',
    initialState: mergeStateWithPreloaded(
        'loginPage',
        initialState
    ) as LoginPage,
    reducers: {
        updateFieldValue: (state, action: PayloadAction<UpdateFieldValue>) => {
            const { data, key } = action.payload;
            const stateReducer = state[key];
            const selectedField: IFieldValue | undefined =
                stateReducer.inputFields.find(
                    field => field.name === data.name
                );
            const type = data.type as
                | 'text'
                | 'number'
                | 'email'
                | 'password'
                | 'checkbox'
                | 'select';

            if (selectedField) {
                switch (type) {
                    case 'text':
                    case 'email':
                    case 'number':
                    case 'password':
                    case 'select':
                        selectedField.value = data.value;
                        break;
                    case 'checkbox':
                        if ('checked' in selectedField) {
                            selectedField.value = data.value;
                            selectedField.checked = data.value === 'true';
                        }

                        break;
                    default: {
                        const exhaustiveCheck: never = type;
                        return exhaustiveCheck;
                    }
                }
            }
        },
        setStateDropDown: (
            state,
            action: PayloadAction<{ value: boolean; id: string }>
        ) => {
            const selectedFieldIndex =
                state.createAccount.inputFields.findIndex(
                    x => x.name === action.payload.id
                );

            (
                state.createAccount.inputFields[
                    selectedFieldIndex
                ] as ISelectModel
            ).visible = action.payload.value;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            loginApi.endpoints.login.matchFulfilled,
            (state, { payload }) => {
                try {
                    if (
                        payload.viewModelAsJson &&
                        payload.viewModelAsJson.length > 0
                    ) {
                        const changePasswordFields: IChangePasswordViewModel['inputFields'] =
                            JSON.parse(
                                payload.viewModelAsJson
                            ) as IChangePasswordViewModel['inputFields'];
                        state.changePassword.inputFields = changePasswordFields;
                    } else {
                        return state;
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    return state;
                }
            }
        );
    },
});

export const { updateFieldValue, setStateDropDown } = slice.actions;
export default slice.reducer;
