import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ICountrySelectorViewModel } from 'Types/Litium';

export const countrySelectorApi = createApi({
    reducerPath: 'countrySelectorApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/countrySelector',
        prepareHeaders: headers => {
            headers.set(
                'litium-request-context',
                JSON.stringify(window.__litium.requestContext)
            );
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: builder => ({
        getCountries: builder.query<ICountrySelectorViewModel, void>({
            query: () => ({
                url: '',
                method: 'GET',
                headers: {
                    RequestVerificationToken: window.localStorage.getItem(
                        'requestVerificationToken'
                    ),
                },
            }),
        }),
    }),
});

export const { useLazyGetCountriesQuery } = countrySelectorApi;
