import { createSlice } from '@reduxjs/toolkit';
import {
    IProductItemViewModel,
    IWishListViewModel,
} from 'Scripts/Types/Litium';
import { mergeStateWithPreloaded } from 'Scripts/Utils/mergeStateWithPreloaded';
import { wishListApi } from 'Services/wishListApi';

export const initialState = {};

const slice = createSlice({
    name: 'wishList',
    initialState: mergeStateWithPreloaded(
        'wishList',
        initialState
    ) as IWishListViewModel,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(
            wishListApi.endpoints.getWishList.matchFulfilled,
            (state, { payload }) => {
                if (Array.isArray(payload)) {
                    state.wishListItems = payload;
                }
            }
        );
        builder.addMatcher(
            wishListApi.endpoints.removeFromWishListPage.matchFulfilled,
            (state, { payload }) => {
                state.wishListItems = payload as IProductItemViewModel[];
            }
        );
    },
});

export default slice.reducer;
