interface MarkupProps {
    html: string;
    cssClass?: string;
    style?: React.CSSProperties | undefined;
    as?: React.ElementType;
    onClickHandler?: (e: React.MouseEvent) => void;
}

//! Keep outcommented for future if reinstate sanitized html via DOMpurify
// DOMPurify.addHook('afterSanitizeAttributes', (node: HTMLImageElement) => {
//     if (node.nodeName === 'IMG' && node.src) {
//         const fakeBaseUrl = 'https://example.com';
//         const imgElement = node;
//         const url = new URL(imgElement.src, fakeBaseUrl);
//         url.searchParams.set('format', 'webp');
//         const newSrc = url.href.replace(url.origin, '');
//         node.src = newSrc.replace(fakeBaseUrl, '');
//     }
// });

const Markup = ({ html, cssClass, as, style, onClickHandler }: MarkupProps) => {
    const Tag = as || 'div';
    const tagProps = Tag === 'button' ? { type: 'button' } : {};

    return (
        <Tag
            {...tagProps}
            onClick={(e: React.MouseEvent) => {
                if (onClickHandler) {
                    onClickHandler(e);
                }
            }}
            className={`-:prose ${cssClass}`}
            style={style}
            dangerouslySetInnerHTML={{ __html: html }}
        />
    );
};

export default Markup;
