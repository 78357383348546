import { createSlice } from '@reduxjs/toolkit';
import { mergeStateWithPreloaded } from 'Scripts/Utils/mergeStateWithPreloaded';
import type { PayloadAction } from '@reduxjs/toolkit';
import { IChangePasswordViewModel } from 'Types/Litium';

export interface IFieldValue {
    value: string;
    name: string;
}

export interface UpdateFieldValue {
    data: IFieldValue;
}

const initialState = {
    inputFields: [],
};

// slice
const slice = createSlice({
    name: 'changePassword',
    initialState: mergeStateWithPreloaded(
        'changePassword',
        initialState
    ) as IChangePasswordViewModel,
    reducers: {
        updateFieldValue: (state, action: PayloadAction<UpdateFieldValue>) => {
            const { data } = action.payload;
            const { inputFields } = state;
            const selectedField = inputFields.find(
                field => field.name === data.name
            );
            selectedField.value = data.value;
        },
        populateInputFields: (
            state,
            action: PayloadAction<IChangePasswordViewModel['inputFields']>
        ) => {
            state.inputFields = action.payload;
        },
    },
});

export const { updateFieldValue, populateInputFields } = slice.actions;
export default slice.reducer;
