import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

type ProductDetailPage = any;

interface ProductProps {
    id: string;
    slideIndex: number;
}

export const productDetailApi = createApi({
    reducerPath: 'productDetailApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/product',
        prepareHeaders: headers => {
            headers.set(
                'litium-request-context',
                JSON.stringify(window.__litium.requestContext)
            );
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: builder => ({
        getProduct: builder.query<ProductDetailPage, ProductProps>({
            query: ({ id }) => ({ url: '', params: { id } }),
            transformResponse: (
                data: object,
                meta: object,
                arg: { slideIndex: number }
            ) => ({
                ...data,
                slideIndex: arg.slideIndex,
            }),
        }),
    }),
});

export const { useLazyGetProductQuery } = productDetailApi;
