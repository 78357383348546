import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { mergeStateWithPreloaded } from 'Scripts/Utils/mergeStateWithPreloaded';

import { productDetailApi } from 'Services/productDetailApi';

import { IProductPageViewModel } from 'Types/Litium';

export interface ProductPageViewModel extends IProductPageViewModel {
    slideIndex: number;
}

export const initialState = {
    slideIndex: null,
};
// slice
const slice = createSlice({
    name: 'productDetailPage',
    initialState: mergeStateWithPreloaded(
        'productDetailPage',
        initialState
    ) as ProductPageViewModel,
    reducers: {
        updateSlideIndex(state, action: PayloadAction<number>) {
            state.slideIndex = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            productDetailApi.endpoints.getProduct.matchFulfilled,
            (state, { payload }) => {
                Object.assign(state, payload);
            }
        );
    },
});
export const { updateSlideIndex } = slice.actions;

export default slice.reducer;
