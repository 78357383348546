import { createSlice } from '@reduxjs/toolkit';
import { mergeStateWithPreloaded } from 'Scripts/Utils/mergeStateWithPreloaded';
import type { PayloadAction } from '@reduxjs/toolkit';
import { INewsletterSignupBlockViewModel } from 'Scripts/Types/Litium';

export interface IFieldValue {
    value: string;
    name: string;
    type: string;
}

export interface UpdateFieldValue {
    data: IFieldValue;
}

const initialState = {
    inputFields: [],
};

const slice = createSlice({
    name: 'newsletter',
    initialState: mergeStateWithPreloaded(
        'newsletter',
        initialState
    ) as INewsletterSignupBlockViewModel,
    reducers: {
        updateFieldValue: (state, action: PayloadAction<UpdateFieldValue>) => {
            const { data } = action.payload;
            const inputFields =
                state.inputFields as INewsletterSignupBlockViewModel['inputFields'];
            const selectedField:
                | INewsletterSignupBlockViewModel['inputFields'][0]
                | undefined = inputFields.find(
                field => field.name === data.name
            );
            selectedField.value = data.value;
        },
        populateInputFields: (
            state,
            action: PayloadAction<
                INewsletterSignupBlockViewModel['inputFields'][0]
            >
        ) => {
            state.inputFields.push(action.payload);
        },
    },
});

export const { updateFieldValue, populateInputFields } = slice.actions;
export default slice.reducer;
