import React, { lazy, Suspense } from 'react';
import { hydrateRoot, createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store from 'Scripts/store';
import ErrorBoundary from 'Components/ErrorBoundary';
import 'Styles/tailwind.css';

const registeredContainers = [];
const renderReact = (element, container) => {
    registeredContainers.push(container);
    const root = createRoot(container);
    root.render(element);
};

const isReactRendered = node =>
    Object.keys(node)?.filter(key => key.includes('_react')).length > 0;

const components = import.meta.glob(
    './{Components,Pages,Blocks,Alpha}/*.{tsx,jsx}',
    { eager: false }
);

const getComponent = (clientOnly, settings, data) => {
    const scriptType =
        settings.scriptType === 'JavaScript' ||
        data?.scriptType === 'JavaScript'
            ? 'jsx'
            : 'tsx';

    const path = `./${clientOnly ? settings.reactType : data?.moduleType}/${
        clientOnly ? settings.reactComponent : data?.moduleName
    }.${scriptType}`;

    const component = components[path];
    if (component) {
        return React.lazy(component);
    }
    console.error('Component not found:', path);
    return null;
};

const bootstrapComponents = () => {
    // This is for hydratizing react components from SSR
    const element = document.querySelectorAll('[data-reactid]');
    if (element.length > 0) {
        Array.from(element).forEach(reactElement => {
            if (!isReactRendered(reactElement)) {
                const settings = reactElement.dataset;
                const clientOnly = settings.reactClientOnly === 'True';
                const data = window.__nrp?.[`${settings.reactid}`];
                if (!clientOnly && !data) return false;
                const Component = getComponent(clientOnly, settings, data);

                if (clientOnly) {
                    renderReact(
                        <ErrorBoundary reactElement={reactElement}>
                            <Provider store={store}>
                                <Suspense>
                                    <Component
                                        {...JSON.parse(settings.reactProps)}
                                    />
                                </Suspense>
                            </Provider>
                        </ErrorBoundary>,
                        reactElement
                    );
                } else {
                    hydrateRoot(
                        reactElement,
                        <ErrorBoundary reactElement={reactElement}>
                            <Provider store={store}>
                                <Suspense>
                                    <Component {...data?.props} />
                                </Suspense>
                            </Provider>
                        </ErrorBoundary>,
                        {
                            identifierPrefix: settings.reactid,
                        }
                    );
                }
            }
        });
    }
};

if (typeof window !== 'undefined') {
    bootstrapComponents();
}
