import { IJsErrorLog } from 'Scripts/Types/Litium';
import React, { Component } from 'react';
import Modal from 'Components/Modal';
import Markup from 'Components/Markup';

interface Props {
    children: React.ReactNode;
    fallback?: React.ReactNode;
    reactElement?: Element;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    static errorLoggedGlobal = false;

    constructor(props: Props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: Error) {
        return {
            hasError: true,
            error,
        };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        if (ErrorBoundary.errorLoggedGlobal) {
            return;
        }

        ErrorBoundary.errorLoggedGlobal = true;

        if (window.__litium?.features?.toggleJsRuntimeErrorLogging) {
            const errorLog: IJsErrorLog = {
                browserVersion: navigator.userAgent,
                url: window.location.href,
                errorMessage: error.toString(),
                stackTrace: errorInfo.componentStack,
            };

            fetch('api/logger/errors', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'litium-request-context': JSON.stringify(
                        window.__litium.requestContext
                    ),
                },
                body: JSON.stringify(errorLog),
            }).catch(err => console.error('Failed to log error:', err));
        }
    }

    render() {
        const { hasError } = this.state;
        const { fallback, children, reactElement } = this.props;

        if (hasError) {
            return (
                fallback ?? (
                    <>
                        <Modal
                            isOpened
                            ariaCloseButtonTranslation={
                                window.__litium?.errorTranslations?.buttonText
                            }
                            backButtonTranslation={
                                window.__litium?.errorTranslations?.buttonText
                            }
                            onClose={() => window.location.reload()}
                            onlyCloseWithButton
                        >
                            {window.__litium?.errorEditorText ? (
                                <Markup
                                    cssClass="h-full"
                                    html={window.__litium?.errorEditorText}
                                />
                            ) : (
                                <p className="h-full w-full text-center">
                                    {
                                        window.__litium?.errorTranslations
                                            ?.errorText
                                    }
                                </p>
                            )}
                        </Modal>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: reactElement.innerHTML,
                            }}
                        />
                    </>
                )
            );
        }
        return children;
    }
}

export default ErrorBoundary;
