import { useEffect, useState } from 'react';

type UseLockedBodyOutput = [boolean, (locked: boolean) => void];

export function useLockedBody(initialLocked = false): UseLockedBodyOutput {
    const [locked, setLocked] = useState(initialLocked);

    // Do the side effect before render
    useEffect(() => {
        if (!locked) {
            return;
        }

        // Save initial body style
        const originalOverflow = document.body.style.overflow;
        const originalPaddingRight = document.body.style.paddingRight;

        const scrollBarWidth = window
            ? window.innerWidth - document.body.clientWidth
            : 0;

        // Lock body scroll
        document.body.style.overflow = 'hidden';

        // Avoid width reflow
        if (scrollBarWidth) {
            document.body.style.paddingRight = `${scrollBarWidth}px`;
            document.body.style.setProperty(
                '--scrollbar-width',
                `${scrollBarWidth}px`
            );
        }

        return () => {
            document.body.style.overflow = originalOverflow;

            if (scrollBarWidth) {
                document.body.style.setProperty('--scrollbar-width', '0px');
                document.body.style.paddingRight = originalPaddingRight;
            }
        };
    }, [locked]);

    // Update state if initialValue changes
    useEffect(() => {
        if (locked !== initialLocked) {
            setLocked(initialLocked);
        }
    }, [initialLocked]);

    return [locked, setLocked];
}

export default useLockedBody;
