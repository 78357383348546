import { createSlice } from '@reduxjs/toolkit';
import { ISearchResultViewModel } from 'Scripts/Types/Litium';
import { mergeStateWithPreloaded } from 'Scripts/Utils/mergeStateWithPreloaded';
import { productFilterApi } from 'Services/productFilterApi';

export const initialState = {};
// slice
const slice = createSlice({
    name: 'searchResultPage',
    initialState: mergeStateWithPreloaded(
        'searchResultPage',
        initialState
    ) as ISearchResultViewModel,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(
            productFilterApi.endpoints.getFiltration.matchFulfilled,
            (state, { payload }) => {
                Object.assign(state, payload);
            }
        );
    },
});

export default slice.reducer;
