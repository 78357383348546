import {
    combineReducers,
    configureStore,
    EnhancedStore,
    Reducer,
} from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import cartReducer from 'Scripts/Slices/cart';
import productListingPageReducer from 'Scripts/Slices/productListingPage';
import formPageReducer from 'Scripts/Slices/formPage';
import productDetailPageReducer from 'Scripts/Slices/productDetailPage';
import myProfilePageReducer from 'Scripts/Slices/myProfilePage';
import loginPageReducer from 'Scripts/Slices/loginPage';
import changePasswordPageReducer from 'Scripts/Slices/changePassword';
import searchResultPageReducer from 'Scripts/Slices/searchResultPage';
import newsLetterReducer from 'Scripts/Slices/newsletter';
import wishListReducer from 'Scripts/Slices/wishList';

import { cartApi } from 'Services/cartApi';
import { formPageApi } from 'Services/formPageApi';
import { productDetailApi } from 'Services/productDetailApi';
import { productFilterApi } from 'Services/productFilterApi';
import { quickSearchApi } from 'Services/quickSearchApi';
import { countrySelectorApi } from 'Services/countrySelectorApi';
import { createAccountApi } from 'Services/createAccountApi';
import { loginApi } from 'Services/loginApi';
import { newsletterApi } from 'Services/newsletterApi';
import { myPageApi } from 'Services/myPageApi';
import { wishListApi } from 'Services/wishListApi';

type PreloadedState = Record<string, unknown>;

interface AsyncReducers {
    [key: string]: Reducer;
}
interface IEnhancedStore extends EnhancedStore {
    injectReducer: (key: string, asyncReducer: Reducer) => void;
    replaceReducer: (newReducer: Reducer) => void;
}

const staticReducers = {
    [cartApi.reducerPath]: cartApi.reducer,
    [quickSearchApi.reducerPath]: quickSearchApi.reducer,
    [productDetailApi.reducerPath]: productDetailApi.reducer,
    [productFilterApi.reducerPath]: productFilterApi.reducer,
    [countrySelectorApi.reducerPath]: countrySelectorApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [formPageApi.reducerPath]: formPageApi.reducer,
    [createAccountApi.reducerPath]: createAccountApi.reducer,
    [newsletterApi.reducerPath]: newsletterApi.reducer,
    [myPageApi.reducerPath]: myPageApi.reducer,
    [wishListApi.reducerPath]: wishListApi.reducer,
    cart: cartReducer,
    productDetailPage: productDetailPageReducer,
    formPage: formPageReducer,
    searchResultPage: searchResultPageReducer,
    productListingPage: productListingPageReducer,
    myProfilePage: myProfilePageReducer,
    loginPage: loginPageReducer,
    newsletter: newsLetterReducer,
    changePassword: changePasswordPageReducer,
    wishList: wishListReducer,
    quickSearch: state =>
        state === undefined ? {} : (state as Record<string, any>),
    navigation: state =>
        state === undefined ? {} : (state as Record<string, any>),
    myPage: state =>
        state === undefined ? {} : (state as Record<string, any>),
};

export const storeConfiguration = (preloadState?: PreloadedState) => {
    const asyncReducers: AsyncReducers = {};
    const rootReducer = combineReducers({
        ...staticReducers,
    });
    const configuredStore = configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware().concat([
                cartApi.middleware,
                productDetailApi.middleware,
                productFilterApi.middleware,
                quickSearchApi.middleware,
                countrySelectorApi.middleware,
                myPageApi.middleware,
                loginApi.middleware,
                newsletterApi.middleware,
                myPageApi.middleware,
                formPageApi.middleware,
                createAccountApi.middleware,
                wishListApi.middleware,
            ]),
        preloadedState: preloadState || {},
    });

    (configuredStore as IEnhancedStore).injectReducer = (
        key: string,
        asyncReducer: Reducer
    ) => {
        asyncReducers[key] = asyncReducer;
        configuredStore.replaceReducer(
            combineReducers({
                ...staticReducers,
                ...asyncReducers,
            })
        );
    };
    return configuredStore;
};

export const store = storeConfiguration({});

setupListeners(store.dispatch);

// Infer the `IRootState` and `AppDispatch` types from the store itself
export type IRootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
export default store as IEnhancedStore;
