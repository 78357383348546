import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IAlphaMiniCartModel } from 'Types/Litium';

type AddToCartPayload = {
    quantity: number;
    articleNumber: string;
};

type UpdateCartRowPayload = {
    type: 'increment' | 'decrement' | 'delete';
    articleNumber: string;
    quantity: string;
};

export const cartApi = createApi({
    reducerPath: 'cartApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/alphaCheckout/',
        prepareHeaders: headers => {
            headers.set(
                'litium-request-context',
                JSON.stringify(window.__litium.requestContext)
            );
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: builder => ({
        getCart: builder.query<IAlphaMiniCartModel, void>({
            query: () => ({
                url: 'miniCart/executeGetMiniCart',
                method: 'GET',
                headers: {
                    RequestVerificationToken: window.localStorage.getItem(
                        'requestVerificationToken'
                    ),
                },
            }),
        }),
        hasCart: builder.query<IAlphaMiniCartModel, void>({
            query: () => ({
                url: 'miniCart/hasAnythingInCart',
                method: 'GET',
                headers: {
                    RequestVerificationToken: window.localStorage.getItem(
                        'requestVerificationToken'
                    ),
                },
            }),
        }),
        addToCart: builder.mutation<IAlphaMiniCartModel, AddToCartPayload>({
            query: item => ({
                url: `miniCart/executeAddToCart`,
                method: 'PUT',
                headers: {
                    RequestVerificationToken: window.localStorage.getItem(
                        'requestVerificationToken'
                    ),
                },
                body: {
                    articleNumber: item.articleNumber,
                    quantity: item.quantity,
                },
            }),
            transformResponse: (data: IAlphaMiniCartModel) => ({
                ...data,
                setCartOpen: true,
            }),
            transformErrorResponse: (response: IAlphaMiniCartModel) => {
                return {
                    ...response,
                    setCartOpen: false,
                    error: response.data.error
                };
            },
        }),
        updateCartRow: builder.mutation<
            IAlphaMiniCartModel,
            UpdateCartRowPayload
        >({
            query: item => {
                let newQuantity = +item.quantity;
                switch (item.type) {
                    case 'increment':
                        newQuantity += 1;
                        break;
                    case 'decrement':
                        newQuantity =
                            newQuantity >= 2 ? newQuantity - 1 : newQuantity;
                        break;
                    case 'delete':
                        newQuantity = 0;
                        break;
                    default:
                        break;
                }
                return {
                    url: `miniCart/executeUpdateOrderRow`,
                    method: 'PUT',
                    headers: {
                        RequestVerificationToken: window.localStorage.getItem(
                            'requestVerificationToken'
                        ),
                    },
                    body: {
                        quantity: newQuantity,
                        articleNumber: item.articleNumber,
                    },
                };
            },
        }),
    }),
});

export const {
    useLazyHasCartQuery,
    useGetCartQuery,
    useAddToCartMutation,
    useUpdateCartRowMutation,
} = cartApi;
