import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface CreateAccountResultData {
    redirectUrl?: string;
    contactId?: string
}
export const createAccountApi = createApi({
    reducerPath: 'createAccountApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/createaccount',
        prepareHeaders: headers => {
            headers.set(
                'litium-request-context',
                JSON.stringify(window.__litium.requestContext)
            );
            headers.set(
                'RequestVerificationToken',
                window.localStorage.getItem('requestVerificationToken')
            );
            headers.set('Content-Type', 'application/json');
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: builder => ({
        createAccount: builder.mutation<
            CreateAccountResultData,
            { form: HTMLFormElement }
        >({
            query: ({ form }) => ({
                method: 'POST',
                url: '',
                body: JSON.stringify(Object.fromEntries(new FormData(form))),
                formData: true,
            }),
        }),
    }),
});

export const { useCreateAccountMutation } = createAccountApi;
