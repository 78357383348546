import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { InputModelArray } from 'Components/Forms/RenderFormField';
import { ISelectModel, IFormPageViewModel } from 'Scripts/Types/Litium';
import { mergeStateWithPreloaded } from 'Scripts/Utils/mergeStateWithPreloaded';

export interface IFieldValue {
    value: string;
    name: string;
    type: string;
}

export interface UpdateFieldValue {
    data: IFieldValue;
}

const initialState = {};
// slice
const slice = createSlice({
    name: 'formPage',
    initialState: mergeStateWithPreloaded(
        'formPage',
        initialState
    ) as IFormPageViewModel,
    reducers: {
        setStateDropDown: (
            state: { inputFields: InputModelArray },
            action: PayloadAction<{ value: boolean; id: string }>
        ) => {
            const selectedFieldIndex = state.inputFields.findIndex(
                x => x.name === action.payload.id
            );

            (state.inputFields[selectedFieldIndex] as ISelectModel).visible =
                action.payload.value;
        },
        updateFieldValue: (state, action: PayloadAction<UpdateFieldValue>) => {
            const { data } = action.payload;
            const { inputFields } = state;
            const selectedField: IFieldValue | undefined = inputFields.find(
                field => field.name === data.name
            );
            const type = data.type as
                | 'text'
                | 'number'
                | 'email'
                | 'password'
                | 'checkbox'
                | 'select'
                | 'textarea';

            if (selectedField) {
                switch (type) {
                    case 'text':
                    case 'email':
                    case 'number':
                    case 'password':
                    case 'select':
                    case 'textarea':
                        selectedField.value = data.value;
                        break;
                    case 'checkbox':
                        if ('checked' in selectedField) {
                            selectedField.value = data.value;
                            selectedField.checked = data.value === 'true';
                        }

                        break;
                    default: {
                        const exhaustiveCheck: never = type;
                        return exhaustiveCheck;
                    }
                }
            }
        },
    },
});

export const { updateFieldValue, setStateDropDown } = slice.actions;
export default slice.reducer;
