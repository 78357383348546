import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IProductItemViewModel } from 'Scripts/Types/Litium';

type WishList = IProductItemViewModel | IProductItemViewModel[];

type WishListPayload = {
    articleNumber: string;
};

export const wishListApi = createApi({
    reducerPath: 'wishListApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/mypages/wishlist/',
        prepareHeaders: headers => {
            headers.set(
                'litium-request-context',
                JSON.stringify(window.__litium.requestContext)
            );
            return headers;
        },
        credentials: 'include',
    }),

    endpoints: builder => ({
        getWishList: builder.query<WishList, void>({
            query: () => ({
                url: 'get',
                method: 'GET',
                headers: {
                    RequestVerificationToken: window.localStorage.getItem(
                        'requestVerificationToken'
                    ),
                },
            }),
        }),
        addToWishList: builder.mutation<WishList, WishListPayload>({
            query: item => ({
                url: `add${window.location.search}`,
                method: 'PUT',
                headers: {
                    RequestVerificationToken: window.localStorage.getItem(
                        'requestVerificationToken'
                    ),
                },
                body: {
                    articleNumber: item.articleNumber,
                },
            }),
        }),
        removeFromWishListPage: builder.mutation<WishList, WishListPayload>({
            query: item => ({
                url: `removeinwishlistpage`,
                method: 'PUT',
                headers: {
                    RequestVerificationToken: window.localStorage.getItem(
                        'requestVerificationToken'
                    ),
                },
                body: {
                    articleNumber: item.articleNumber,
                },
            }),
        }),
        removeFromWishList: builder.mutation<WishList, WishListPayload>({
            query: item => ({
                url: `remove${window.location.search}`,
                method: 'PUT',
                headers: {
                    RequestVerificationToken: window.localStorage.getItem(
                        'requestVerificationToken'
                    ),
                },
                body: {
                    articleNumber: item.articleNumber,
                },
            }),
        }),
    }),
});

export const {
    useGetWishListQuery,
    useAddToWishListMutation,
    useRemoveFromWishListMutation,
    useRemoveFromWishListPageMutation,
} = wishListApi;
