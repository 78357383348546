import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ISearchItemExtended } from 'Types/Litium';

type IQuickSearch = string;

export const quickSearchApi = createApi({
    reducerPath: 'quickSearchApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/quickSearch',
        prepareHeaders: headers => {
            headers.set(
                'litium-request-context',
                JSON.stringify(window.__litium.requestContext)
            );
            headers.set('Content-Type', 'application/json');
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: builder => ({
        quickSearch: builder.mutation<ISearchItemExtended[], IQuickSearch>({
            query: query => ({
                url: '',
                method: 'POST',
                headers: {
                    RequestVerificationToken: window.localStorage.getItem(
                        'requestVerificationToken'
                    ),
                },
                body: JSON.stringify(query),
            }),
        }),
    }),
});

export const { useQuickSearchMutation } = quickSearchApi;
