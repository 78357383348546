import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

type ProductFilter = any;

type LoadMoreProducts = any;

export type Facets = Partial<Facet>[];

type Facet = Record<string, string>;

const myPrepareSearchParams = (facets: Facets) =>
    encodeURI(facets.map(facet => `${facet.name}=${facet.value}`).join('&'));

export const productFilterApi = createApi({
    reducerPath: 'productFilterApi',
    baseQuery: fetchBaseQuery({
        baseUrl: '/api/productFilter/',
        prepareHeaders: headers => {
            headers.set(
                'litium-request-context',
                JSON.stringify(window.__litium.requestContext)
            );
            return headers;
        },
        credentials: 'include',
    }),
    endpoints: builder => ({
        getFiltration: builder.query<ProductFilter, Facets>({
            query: (facets = []) => ({
                url: `filteredResult?${myPrepareSearchParams(facets)}`,
            }),
        }),
        getLoadMoreCategoryProducts: builder.query<LoadMoreProducts, Facets>({
            query: (facets = []) => ({
                url: `loadMoreCategoryProducts?${myPrepareSearchParams(
                    facets
                )}`,
            }),
        }),
        getLoadMoreSearchResultProducts: builder.query<
            LoadMoreProducts,
            Facets
        >({
            query: (facets = []) => ({
                url: `loadMoreSearchResultProducts?${myPrepareSearchParams(
                    facets
                )}`,
            }),
        }),
    }),
});

export const {
    useLazyGetFiltrationQuery,
    useLazyGetLoadMoreCategoryProductsQuery,
    useLazyGetLoadMoreSearchResultProductsQuery,
} = productFilterApi;
